import { CONTRACTS } from '@/constants/routes';

export default function accessToTemplates() {
  return ({ next, store }) => {
    const access = store.getters['user/companySectionsRights'];

    if (access.find(item => item.name === 'docuseal')) {
      return next();
    }

    return next({ name: CONTRACTS });
  };
}
